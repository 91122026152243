var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    [
      _c(
        "v-card",
        {
          staticClass: "pa-12 border-primary border border-solid",
          attrs: { flat: "" },
        },
        [
          _c("page-title", {
            attrs: { "page-title": _vm.previousSection?.title + ` has ended!` },
          }),
          _c("p", { staticClass: "pt-8 pb-12" }, [
            _vm._v(
              " The next section will start in 5 minutes. You can also start by clicking the next button. "
            ),
          ]),
          _c(
            "v-row",
            {
              staticClass: "d-flex flex-column",
              attrs: { justify: "center", align: "center" },
            },
            [
              _c("h2", { staticClass: "pb-4" }, [
                _vm._v(_vm._s(_vm.nextSection.title)),
              ]),
              _c("p", { staticClass: "pb-2" }, [_vm._v("Will start in")]),
              _c("fluent-time-display", {
                attrs: { duration: 300 },
                on: { "time-over": _vm.gotoNextSection },
              }),
              _c(
                "v-btn",
                {
                  staticClass:
                    "white--text bg-primary pa-6 mt-6 font-weight-bold font-size-20",
                  attrs: { depressed: "" },
                  on: { click: _vm.gotoNextSection },
                },
                [_vm._v("Start next section")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }