<template>
  <m-container>
    <v-card class="pa-12 border-primary border border-solid" flat>
      <page-title
        :page-title="previousSection?.title + ` has ended!`"
      ></page-title>
      <p class="pt-8 pb-12">
        The next section will start in 5 minutes. You can also start by
        clicking the next button.
      </p>
      <v-row justify="center" align="center" class="d-flex flex-column">
        <h2 class="pb-4">{{ nextSection.title }}</h2>
        <p class="pb-2">Will start in</p>
        <fluent-time-display
          :duration="300"
          @time-over="gotoNextSection"
        ></fluent-time-display>
        <v-btn
          class="white--text bg-primary pa-6 mt-6 font-weight-bold font-size-20"
          depressed
          @click="gotoNextSection"
          >Start next section</v-btn
        >
      </v-row>
    </v-card>
  </m-container>
</template>

<script>
import FluentTimeDisplay from "#ef/exam/components/FluentTimeDisplay.vue";

export default {
  name: "SectionBreak",
  components: { FluentTimeDisplay },
  props: ["exam", "nextSection"],
  computed: {
    previousSection() {
      return this.exam?.sections[
        this.exam?.sections?.indexOf(this.nextSection) - 1
      ];
    }
  },
  methods: {
    gotoNextSection() {
      this.$router.replace({
        name: "studentsAppearSectionExamRoutes",
        params: {
          appear: "appear",
          testId: this.$attrs.testId,
          sectionId: this.nextSection.order
        }
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
